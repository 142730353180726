import React, { useState }  from 'react';

//  Custom CSS
import '../Home.css'
//  Bootstrap
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from 'react-bootstrap/Button';
// Images
//  import quadchainLogo from '../Images/quadchainLogo.svg';
import quadchain_Logo from '../Images/Quadchain_logo.svg';
import arrow from '../Images/arrow-violet.svg';
import dropdownicon1 from '../Images/icon-dropdown1.svg';
import dropdownicon2 from '../Images/icon-dropdown2.svg';
import dropdownicon3 from '../Images/icon-dropdown3.svg';
import dropdownicon4 from '../Images/icon-dropdown4.svg';
import { ReactComponent as DropdownIcon } from '../Images/dropdownarrow.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import ContactFormModal from '../SubPageComponents/ContactFormModal'

function Header() {
    const handleNavigate = () => {
        window.location.href = '/industries'
    };
    const navigate = useNavigate();
    // const handleClick = (type) => {
        // startTransition(() => { navigate('/industries#industries', { state: { selectedCategory: type } });});
    //   };
    const location = useLocation();

    const handleClick = (type) => {
        // If already on the industries page
        if (location.pathname === '/industries') {
            // Scroll to the #industries section
            const industriesSection = document.getElementById('industries');
            if (industriesSection) {
                industriesSection.scrollIntoView({ behavior: 'smooth' });
                navigate(location.pathname, { replace: true, state: { selectedCategory: type } });
            }
        } else {
            // Navigate to /industries page and include the #industries section
            navigate('/industries#industries', { state: { selectedCategory: type } });
            window.history.replaceState({ ...window.history.state, selectedCategory: type }, '');
        }
    };
    const [modalShow, setModalShow] = useState(false);

    const handleShow = () => setModalShow(true);
    const handleClose = () => setModalShow(false);
  
    // const handleNavigateServices = () => {
    //     window.location.href = '/services'
    // };
    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary mt-0" style={{ zIndex: "1000" }}>
            <Container className='header' fluid>
                <Navbar.Brand href="/" className='div-center' style={{ marginLeft: "1%", marginTop: "2vh" }}>
                    <img className='quadchainlogo' src={quadchain_Logo} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className='brandspacing'>
                    <Nav style={{ marginLeft: "28.5%" }}>
                        {/* <Nav.Toggle as={arrow} id="collapsible-nav-dropdown">
                                Custom toggle
                            </Nav.Toggle> */}
                        <NavDropdown title={
                            <span style={{ cursor: 'pointer' }}>
                                Solutions
                                <DropdownIcon className="mx-1" />

                            </span>
                        } id="collapsible-nav-dropdown" className="custom-dropdown mx-xxl-3 mx-xl-2 mx-lg-1 mx-xs-1">
                            <div className="flexrow-header"><div className='col-sm-6'>
                                {/* <NavDropdown.Item href="/appointment-setter" ><div className='circlestyle '><img src={dropdownicon1} className=' centericon' /></div><span className='dropdown-text'>Appointment Setter</span></NavDropdown.Item> */}
                                <NavDropdown.Item href="/diagnosis-ai" ><div className='circlestyle '><img src={dropdownicon1} className=' centericon' /></div><span className='dropdown-text'>Diagnosis AI</span></NavDropdown.Item>
                                <NavDropdown.Item href="/image-extraction"><div className='circlestyle'><img src={dropdownicon3} className=' centericon' /></div><span className='dropdown-text'>Image Extraction</span></NavDropdown.Item>
                            </div>
                                <div className='col-sm-6'>
                                    {/* <NavDropdown.Item href="/anomaly-detection"><div className='circlestyle'><img src={dropdownicon4} className='centericon' /></div><span className='dropdown-text'>Anomaly Detection</span></NavDropdown.Item> */}
                                    <NavDropdown.Item href="/knowledge-assistant"><div className='circlestyle'><img src={dropdownicon4} className='centericon' /></div><span className='dropdown-text'>Knowledge Assistant</span></NavDropdown.Item>
                                    <NavDropdown.Item href="/anomaly-detection" ><div className='circlestyle'><img src={dropdownicon2} className=' centericon' /></div><span className='dropdown-text'>Video Analytics</span></NavDropdown.Item>
                                </div></div>
                        </NavDropdown>

                        {/* <NavDropdown title={
                            <span onClick={handleNavigateServices} style={{ cursor: 'pointer' }}>
                                Services
                                <DropdownIcon className="mx-1" />

                            </span>
                        } id="collapsible-nav-dropdown" className="custom-dropdown mx-xxl-3 mx-xl-2 mx-lg-1">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        </NavDropdown> */}

                        <Nav.Link className="mx-xxl-3 mx-xl-2 mx-lg-1" href="services">Services</Nav.Link>

                        <NavDropdown title={
                            <span>
                                <span onClick={handleNavigate} style={{ cursor: 'pointer' }}>
                                    Industries
                                </span>
                                <DropdownIcon className="mx-1" />
                            </span>
                        } id="collapsible-nav-dropdown" className="custom-dropdown mx-xxl-3 mx-xl-2 mx-lg-1">
                            <div className="flexrow-header"><div className='col-sm-6'>
                                <NavDropdown.Item onClick={() => { handleClick("Banking & Financial Services") }}><div className='circlestyle'><img src={dropdownicon2} className='centericon' /></div><span className='dropdown-text' >Banking &
                                    <br /> Financial Services</span></NavDropdown.Item>
                                <NavDropdown.Item onClick={() => { handleClick("Life Sciences & Healthcare") }}><div className='circlestyle'><img src={dropdownicon3} className='centericon' /></div><span className='dropdown-text'>Life Sciences & <br />  Healthcare</span></NavDropdown.Item>
                            </div>
                                <div className='col-sm-6'> <NavDropdown.Item onClick={() => { handleClick("Energy") }}><div className='circlestyle'><img src={dropdownicon4} className=' centericon' /></div><span className='dropdown-text'>Energy</span></NavDropdown.Item>
                                    {/* <NavDropdown.Item href="industries#industries"><div className='circlestyle'><img src={dropdownicon1} className=' centericon' /></div><span className='dropdown-text'>Retail</span></NavDropdown.Item> */}
                                    <NavDropdown.Item onClick={() => { handleClick("Retail") }}><div className='circlestyle'><img src={dropdownicon1} className=' centericon' /></div><span className='dropdown-text'>Retail</span></NavDropdown.Item>
                                </div>
                            </div>
                        </NavDropdown>

                        <Nav.Link className="mx-xxl-3 mx-xl-2 mx-lg-1" href="about-us">About Us</Nav.Link>
                        <Nav.Link className="mx-xxl-3 mx-xl-2 mx-lg-1" href="contact-us">Contact Us</Nav.Link>
                        <Button className="mx-xxl-3 mx-xl-2 mx-lg-1 demo" style={{ border: "0.85px solid #B86BEB", background: "transparent", textAlign: "center" }} onClick={handleShow} >Book a Demo <img className='arrow-button' src={arrow} /> </Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
            <ContactFormModal show={modalShow} handleClose={handleClose} />
        </Navbar>
    )
}

export default Header