// import './App.css';
// import Home from './Home'

// function App() {
//   return (
//     <div className='App' >
//        <Home />

//     </div>
//   );
// }

// export default App;

import React, { Component } from "react";
// import returnStoreAndPersistor from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
// import history from "./common/history";
// import "react-toastify/dist/ReactToastify.css";
// import styles from './App.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AppRouter from './AppRouter'
import Footer from './SubPageComponents/Footer'
import Header from './SubPageComponents/Header'

// const { store, persistor } = returnStoreAndPersistor();
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  appInsights = null;

  render() {
    return (
      <div  style={{backgroundColor:"rgba(6, 0, 12, 1)"}}>
        {/* <Provider> */}
          <Router>
            {/* <GlobalNavigation /> */}
            <Header />
            <div style={{zIndex:"1000"}} >
              <AppRouter />
            </div>
            <Footer />
          </Router>
        {/* </Provider> */}
      </div>
    );
  }
}

export default App;
