// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Images/closegrey.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
    background: linear-gradient(114.88deg, rgba(0, 10, 31, 1) -2.45%, rgba(17, 1, 31, 1) 58.38%, rgba(0, 10, 31, 1) 101.84%);
    color: white;
}

.textarea-custom.form-control {
    height: 20vh !important;
}

.modal-header  {
    /* background: url('../Images/services-bg2.svg'); */
    /* background-color: rgba(10, 7, 16, 1); */
    background: linear-gradient(114.88deg, rgba(0, 10, 31, 1) -2.45%, rgba(17, 1, 31, 1) 58.38%, rgba(0, 10, 31, 1) 101.84%);
    color: white;
    border-bottom: 1px solid #B86BEB;
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
    --bs-modal-inner-border-radius: 0px !important;
}

.form-control {
    --bs-body-color: rgb(156, 156, 156);
    --bs-body-bg: rgba(88, 90, 96, 0.17);
    --bs-border-color: rgba(88, 90, 96, 0.17);
    color: white !important;
    width: 90%;
    --bs-border-radius: 10px;
    height: 8vh;
}

.form-control::placeholder {
    color: white !important;
    font-weight: 50;
    opacity: 1;
}

.btn-close {
    --bs-btn-close-color: #B86BEB;
    --bs-btn-close-bg: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

/* .modal-content {
    --bs-modal-border-color: #B86BEB;
} */`, "",{"version":3,"sources":["webpack://./src/css/ContactFormModal.css"],"names":[],"mappings":"AAAA;IACI,wHAAwH;IACxH,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,mDAAmD;IACnD,0CAA0C;IAC1C,wHAAwH;IACxH,YAAY;IACZ,gCAAgC;IAChC,uCAAuC;IACvC,wCAAwC;IACxC,8CAA8C;AAClD;;AAEA;IACI,mCAAmC;IACnC,oCAAoC;IACpC,yCAAyC;IACzC,uBAAuB;IACvB,UAAU;IACV,wBAAwB;IACxB,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,UAAU;AACd;;AAEA;IACI,6BAA6B;IAC7B,0DAAiD;AACrD;;AAEA;;GAEG","sourcesContent":[".modal-body {\n    background: linear-gradient(114.88deg, rgba(0, 10, 31, 1) -2.45%, rgba(17, 1, 31, 1) 58.38%, rgba(0, 10, 31, 1) 101.84%);\n    color: white;\n}\n\n.textarea-custom.form-control {\n    height: 20vh !important;\n}\n\n.modal-header  {\n    /* background: url('../Images/services-bg2.svg'); */\n    /* background-color: rgba(10, 7, 16, 1); */\n    background: linear-gradient(114.88deg, rgba(0, 10, 31, 1) -2.45%, rgba(17, 1, 31, 1) 58.38%, rgba(0, 10, 31, 1) 101.84%);\n    color: white;\n    border-bottom: 1px solid #B86BEB;\n    border-top-left-radius: none !important;\n    border-top-right-radius: none !important;\n    --bs-modal-inner-border-radius: 0px !important;\n}\n\n.form-control {\n    --bs-body-color: rgb(156, 156, 156);\n    --bs-body-bg: rgba(88, 90, 96, 0.17);\n    --bs-border-color: rgba(88, 90, 96, 0.17);\n    color: white !important;\n    width: 90%;\n    --bs-border-radius: 10px;\n    height: 8vh;\n}\n\n.form-control::placeholder {\n    color: white !important;\n    font-weight: 50;\n    opacity: 1;\n}\n\n.btn-close {\n    --bs-btn-close-color: #B86BEB;\n    --bs-btn-close-bg: url(\"../Images/closegrey.svg\");\n}\n\n/* .modal-content {\n    --bs-modal-border-color: #B86BEB;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
