import React, { startTransition } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { useNavigate } from 'react-router-dom';

import quadchainLogo from '../Images/quadchainlogov2.svg';
import '../css/BannerLayout.css';
import footerlogo from '../Images/footer-logo.svg';

import '../css/footer.css';
// import logo from '../Images/quadchainlogov2.svg'; // Update the path to your logo image
import logo from '../Images/Quadchain_logo.svg';
import quadchainText from '../Images/footer-logo.svg'; // Update the path to the Quadchain text image
import backgroundImage from '../Images/footer-bg.svg';

function Footer() {
    const navigate = useNavigate();
    const navigatetoservices = (href) => {
        // setFadeOut(true);
        // setTimeout(() => {
        //     navigate('/Body');
        // }, 500);
        startTransition(() => {
            navigate(href);
        });
    };
    return (
        <footer className="footer-container">
            <div className="footer-content">
            <div className='oval-shade'></div>
                <div className="footer-left">
                    <img src={logo} alt="Quadchain Logo" className="logo" />
                    {/* <p className='footer-submsg'>© Copyright 2024<br /><span >All Rights Reserved</span></p> */}
                    <span className='footer-submsg' style={{lineHeight:"20px"}}>
                            © Copyright 2025</span><br />
                        <span className='footer-submsg' style={{lineHeight:"0px"}}> All Rights Reserved</span>
                       
                </div>
                <div className="footer-right">
                    <img src={quadchainText} alt="Quadchain Text" className="quadchain-text" />
                    <div className="footer-links">
                        <div>
                            <div className="p-2 footer-submsg" onClick={() => { navigatetoservices("/services") }} >Services</div>
                            <div className="p-2 footer-submsg" onClick={() => { navigatetoservices("/diagnosis-ai") }}>Solutions</div>
                            <div className="p-2 footer-submsg" onClick={() => { navigatetoservices("/industries") }}>Industries</div>
                        </div>
                        <div>
                        <div className="p-2 footer-submsg" onClick={() =>{navigatetoservices("/about-us")}}>About Us</div>
                                <div className="p-2 footer-submsg" onClick={() =>{navigatetoservices("/contact-us")}}>Contact Us</div>
                                <div className="p-2 footer-submsg" style={{color:"transparent"}}>Resources</div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer