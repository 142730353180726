import React, { Suspense, lazy } from 'react';
// import { Switch, Route } from "react-router-dom";
import { Routes, Route } from 'react-router-dom';
import ScrollToTop from './SubPageComponents/ScrollToTop';
const HomePage = lazy(() => import('./PageComponents/HomePage'));
const ContactUsPage = lazy(() => import('./PageComponents/ContactUsPage'));
const AboutUsPage = lazy(() => import('./PageComponents/AboutUsPage'));
const IndustriesPage = lazy(() => import('./PageComponents/IndustriesPage'));
const ServicesPage = lazy(() => import('./PageComponents/ServicesPage'));
const KnowledgeExchange = lazy(() => import('./PageComponents/KnowledgeExchange'));
const AnomalyDetection = lazy(() => import('./PageComponents/AnomalyDetection'));
const ImageExtraction = lazy(() => import('./PageComponents/ImageExtraction'));
const AppointmentSetter = lazy(() => import('./PageComponents/AppointmentSetter'));
const MedicalAI = lazy(() => import('./PageComponents/MedicalAI'));

const AppRouter = () => {
    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                {/* <Route path="/industries" element={<IndustriesPage />} /> */}
                <Route
                    path="/industries"
                    element={
                        <Suspense fallback={<div>Loading...</div>}>
                            <IndustriesPage />
                        </Suspense>
                    }
                />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/knowledge-assistant" element={<KnowledgeExchange />} />
                <Route path="/anomaly-detection" element={<AnomalyDetection />} />
                <Route path="/image-extraction" element={<ImageExtraction />} />
                <Route path="/appointment-setter" element={<AppointmentSetter />} />
                <Route path="/diagnosis-ai" element={<MedicalAI />} />
            </Routes>
        </>
    );
}

export default AppRouter;